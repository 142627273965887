<template>
  <b-row class="match-height m-0">
      <b-card
        img-src="@/assets/images/home/creador_header.jpg"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        footer-class="without-border"
      >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="profile_image"
              href="#"
              @click="modalImage()"
            />
          </div>
        </div>
        <h3>
          {{
            `${this.profile.first_name.toUpperCase()} ${this.profile.last_name.toUpperCase()}`
          }}
        </h3>
        <h6 class="text-muted">
          {{ this.profile.email }}
        </h6>
        <h6 class="text-muted d-flex align-items-center justify-content-center">
          <country-flag-svg v-if="this.profile.country" class="mr-1" :country="this.profile.country"/>
          {{ this.profile.country }}
        </h6>
        <hr class="mb-1" />
       
        <b-row class="container-button-social">
          <div class="col-12 col-sm-6 mb-2 mb-sm-0 text-social">
           <b-link @click="redirectDetailConnection()"> {{ $t("dashboard.socialOverview") }}</b-link>
          </div>
          <div class="col-12 col-sm-6 p-0 mb-2 mb-sm-0">
            <b-button
              class="button-add-network"
              @click="show_sider_network = true"
            >
              <span class="text-add-network">{{ $t("buttonAddNetwork") }}</span>
            </b-button>
            <span
            class="sider-why d-flex justify-content-center"
            @click="show_sider_why = true"
            > 
              {{ $t("dashboard.whySider") }} 
            </span>
          </div>
        </b-row>
        <div
            v-if="profile.networks.length > 0"
            class="
              d-flex
              justify-content-center
              align-items-center
              flex-wrap
            "
          >
             <b-media
              v-for="(item, index) in profile.networks"
              :key="index"
              no-body
            >
              <b-media-aside class="mr-1 mt-1">
                <b-avatar
                  variant="ligth"
                  :id="'tooltip-network-' + item.uuid"
                  target="_blank"
                  size="30"
                  v-if="item.connected"
                  :class="getLocalNetworkName(item.network)"
                  :href="getLinkNetwork(item)"
                >
                <b-img :src="getIconWhite(item.network)" :class="getIconColor(item.network)" fluid></b-img>
                </b-avatar>
                <div v-if="!item.connected" class="position-relative">
                  <b-avatar
                    v-if="!item.connected"
                    :id="'tooltip-network-' + item.uuid"
                    size="30"
                    button
                    @click="redirectSocialSettings()"
                  >
                    <b-img :src="getIconWhite(item.network)" :class="`${item.network === 'spotify' ? 'paddind-2' : 'paddind-7'}`" fluid></b-img>
                  </b-avatar>
                  <b-icon v-if="item.network !== 'blog'"
                    :id="`connect-${item.uuid}`"
                    icon="exclamation-circle-fill"
                    variant="warning"
                    @click="redirectSocialSettings()"
                    class="icon-alert-profile"
                  ></b-icon>
                </div>
              </b-media-aside>
              <b-tooltip :target="`connect-${item.uuid}`" placement="bottom">{{
                $t("connectNetwork")
              }}</b-tooltip>
              <b-tooltip
                :target="'tooltip-network-' + item.uuid"
                triggers="hover"
                placement="top"
              >
                @{{ item.username }}
              </b-tooltip>
            </b-media>
          </div>
        <b-row>
          <div class="col-12 col-sm-6 container-metrics px-0">
            <b-col>
              <span class="title-metrics">{{ $t("dashboard.totalFollowers") }}</span>
            </b-col>
              <span> {{ getFormat(totalFolllowers(profile)) }}</span>
          </div>
          <div class="col-12 col-sm-6 container-metrics">
            <b-col>
              <span class="title-metrics">
                {{ $t("dashboard.ER") }}
              </span>
            </b-col>
              <span v-if="er_calculate > 0"> {{ decimalFixed(er_calculate, 2) }}%</span>
              <span v-else> -- </span>
          </div>
        </b-row>
      
      </b-card>

    <b-form-file
      v-model="avatar"
      accept=".jpg, .png"
      @change="changeAvatar"
      ref="avatar-input"
      class="d-none"
    ></b-form-file>
      
    <sider-network 
     class="open-sidebar-search"
     @close_siderbar="closeSiderNetwork"
     @updated_network="getNetworks"
      :show_sider_network="show_sider_network"
      v-if="show_sider_network"
    />

    <sider-why
     class="open-sidebar-search"
     @close_siderbar_why="closeSiderWhy"
     :show_sider_why="show_sider_why"
     v-if="show_sider_why"
    />
  </b-row>
</template>

<script>
import {
  BLink,
  BFormFile,
  // BIcon,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BMedia,
  BMediaAside,
  BTooltip,
  BButton,
  BImg,
} from "bootstrap-vue";
import others from "@/services/others";
import profile_services from '@/services/settings'
import abilities from '@/libs/acl/abilities'
import service from "@/services/dashboard";
import { getLocalNetworkName, getIconWhite, getIconColor } from '@/libs/utils/icons'
import { decimalFixed, getFormat } from '@/libs/utils/formats';
import { hasPermission, setuserData } from '@/libs/utils/user';
import { getURL } from '@/libs/utils/urls';
const toastification_component = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: "CardCreator",
  components: {
    BLink,
    BFormFile,
    // BIcon,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BTooltip,
    BButton,
    BImg,
    siderNetwork: () => import('@/views/pages/proposals/stepper/siderNetwork.vue'),
    siderWhy: () => import('@/views/pages/proposals/stepper/siderWhy.vue'),
    countryFlagSvg: () => import('../../components/countryFlagSvg.vue'),
  },
  data() {
    return {
      getLocalNetworkName,
      getIconWhite,
      getIconColor,
      decimalFixed,
      getFormat,
      show_sider_network: false,
      show_sider_why: false,
      avatar: null,
      profile_image: null,
      netIconsColor: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/wordpress-icon.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
      },
      profile: {
        first_name: "",
        last_name: "",
        profile_image: "",
        country: "",
        networks:[],
      },
      campaign: {},
      campaignView: false,
      downImg: require("@/assets/images/banner/banner_brand.png"),
      imgCreator: require("@/assets/images/banner/banner_creator.png"),
      hRol: "",
      width: window.screen.width,
      er_calculate: 0
    };
  },
  created() {
    this.getGroup();
    this.getNetworks()
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    redirectDetailConnection() {
      const first_network = this.profile.networks[0];
      if (first_network && this.profile.meta_info && first_network.network && first_network.username && first_network.vanity_name) {
        this.$router.push({ 
          name: 'detail_connection', 
          params: { 
            network: first_network.network, 
            username: first_network.username, 
            user: first_network.vanity_name, 
            section: 'Overview' 
          } 
        }).catch(() => {});
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
      }
    },
    getNetworks() {
      others.getSocialNetworks().then((response) => {
        this.profile.networks = response.results;
        let er_total = 0;
        let valid_er = 0;
        response.results.forEach(network => {
          if (parseFloat(network.er) > 0) {
            er_total += parseFloat(network.er)
            valid_er++
          }
        });

        if (valid_er) this.er_calculate = er_total / valid_er
      })
        .catch(() => {
        });
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    closeSiderWhy() {
      this.show_sider_why = false
    },
    getGroup() {
      const user = JSON.parse(localStorage.userData);
      this.groupRol = user.groups[0].name;
      this.hRol =
        this.groupRol === "creator" ? "height:250px" : this.width > 1400 || this.width < 1300 ? "height:350px" : "";
      this.campaignView = hasPermission('view_campaign');
    },
    getProfile() {
      const user = JSON.parse(localStorage.userData);
      others.getProfile().then((response) => {
        this.profile = response.user;
        this.profile_image = this.getImgProfile(this.profile.profile_image);
        setuserData(response, user.ability);
      });
    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    modalImage() {
      this.avatar = null;
      this.$refs['avatar-input'].$refs['input'].click()
    },
    changeAvatar() {
      setTimeout(() => {
        if (this.avatar) {
          profile_services.changeAvatar(this.avatar).then(result => {
            toastification_component().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('dashboard.saved'),
                  variant: 'success',
                  icon: 'CheckCircleIcon',
                  text: this.$t('dashboard.saved_avatar'),
                }
              })
            })
            const readAbility = abilities.getAbilitie(result.user)
            setuserData(result, readAbility);
            this.$root.$emit('profile_update');
            this.profile_image = this.getImgProfile(result.user.profile_image);
          }).catch(() => {
          });
        }
      }, 200);
    },
    getLinkNetwork(profile) {
      if (profile.network === 'facebook' && profile.link) return profile.link
      return getURL(profile.network, profile.username, profile.is_channel)
    },
    getSrcIconColor(network) {
      const index_network = Object.keys(this.netIconsColor).indexOf(network)

      if (index_network === -1) return

      return Object.values(this.netIconsColor)[index_network]
    },
    redirectSocialSettings() {
      this.$router.push('/pages/account-setting/social')
    },
    totalFolllowers(profile) {
      let sum = 0

      profile.networks.forEach(item => { 
        sum += item.followers
      });
      return sum

    }
  },
};
</script>
<style scoped>
.paddind-7 {
  padding: 7px;
}
.paddind-2 {
  padding: 2px;
}
.text-social {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #0e131a;
  font-weight: bold;
}
.card-profile {
  min-height: 500px;
}
.container-title-sider {
  border: 1px solid rgb(206, 212, 218) !important;
}
.icon-alert-profile{
  position:absolute;
  z-index: 2;
  bottom: 0;
  right: 5px;
  font-size: 11px;
  cursor: pointer;
}
.container-metrics {
  margin-top: 22px;
  text-align: center; 
  
}
.title-metrics {
  text-transform: uppercase !important;
  color: #6c757d;
  font-weight: 400 !important;
}
.metrics {
  font-weight: 500 !important;
  color: #212529 !important;
}
.container-button-social {
  justify-content: space-between;
}
.text-add-network {
  font-size: small !important;
}
.sider-why{
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}
.button-add-network {
  width: 140px;
  padding-left: 11px !important;
  padding-right: 11px !important;
  background: #0096fe !important;
  border: none;
}
.button-add-network:focus {
  width: 140px;
  padding-left: 11px !important;
  padding-right: 11px !important;
  background: #0096fe !important;
  border: none;
}
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>